const ROUTES = {
  format: "/format",
  formatdetails: "/formatdetails",
  deck: "/deck",
  deckdetails: "/deckdetails",
  signin: "/signin",
  signup: "/signup",
  ownformat: "/ownformats",
  userformat: "/userformats",
  owndecks: "/owndecks",
  favorites: "/favorites",
  howto: "/howto",
  passwordforget: "/passwordforget",
  passwordchange: "/passwordchange",
  emailverify: "/emailverify",
  changelog: "/changelog",
  accountinfo: "/accountinfo",
  reports: "/reports"
};

export default ROUTES;